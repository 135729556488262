.dex-chart {
  width: 140px;
  max-width: 100%;
  height: auto;
  position: relative;

  .dex-value {
    position: absolute;
    font-size: 32px;
    bottom: -1%;
    left: 0;
    width: 100%;
    text-align: center;
  }

  &.dex-chart--sm {
    svg {
      max-width: 50px;
    }
    .dex-value {
      font-size: 14px;
    }
  }

  svg {
    .dex-chart-line {
      stroke-linecap: round;
      stroke-width: 4px;
    }

    .dex-chart-bg {
      stroke: $primary;
      opacity: 0.4;
    }

    .dex-chart-value {
      transition: all 1s ease;
      stroke: $primary;
      stroke-dasharray: 100;
    }
  }
  &.tx-dark-red {
    svg .dex-chart-value,
    .dex-chart-bg {
      stroke: $dark-red;
    }
  }
  &.tx-warning {
    svg .dex-chart-value,
    .dex-chart-bg {
      stroke: $warning;
    }
  }
  &.tx-dark-green {
    svg .dex-chart-value,
    .dex-chart-bg {
      stroke: $dark-green;
    }
  }
}
