body, html, #root {
  height: 100%;
}

body {
  //background: url("../assets/images/BG.png") center bottom no-repeat;
  //background-size: cover;
}

svg,
img {
  max-width: 100%;
  height: auto;
}

#root {
  > div {
    min-height: 100%;
  }
}

.preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  backdrop-filter: blur(3px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  z-index: 100;
}

.cur-pointer {
  cursor: pointer;
}

#walletconnect-wrapper .walletconnect-modal__mobile__toggle a {
  color: #333;
}

.word-break {
  word-break: break-all;
}

.exchange-logo-list {
  &--item {
    display: inline-block;
    border: 2px solid $secondary;
    z-index: 2;
    position: relative;
    border-radius: 1000px;
    overflow: hidden;
    &:not([class*="wd-"]) {
      width: 20px;
      height: 20px;
    }

    &.no-border {
      border: none;
    }

    &:nth-child(2) {
      z-index: 1;
      margin-left: -5px;
    }

    img {
      width: 100%;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: -2px;
      right: -2px;
      top: -2px;
      bottom: -2px;
      background-color: $secondary;
      border-radius: 1000px;
      z-index: -1;
    }
  }
  &--mobile {
    position: absolute;
    bottom: -3px;
    left: -3px;
    right: -3px;
    display: flex;
    justify-content: space-between;
    > div {
      display: flex;
      width: 16px;
      height: 16px;
      border-width: 1px;
      img {
        width: 100% !important;
        height: 100% !important;
      }

      &:before {
        left: -1px;
        right: -1px;
        top: -1px;
        bottom: -1px;
      }

      &:nth-child(2) {
        margin-left: 0;
      }
    }
  }
}

.list-item {
  color: $white;
  text-decoration: none;

  &:hover {
    color: $white;
    text-decoration: none;
  }
}

.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: $body-bg;
  z-index: 1000;

  @supports (backdrop-filter: blur(10px)) {
    background: rgba($body-bg, 0.7);
    backdrop-filter: blur(7px);
  }
  @supports (-webkit-backdrop-filter: blur(10px)) {
    background: rgba($body-bg, 0.7);
    -webkit-backdrop-filter: blur(7px);
  }
}

.bg-semi-transparent {background: rgba(156, 177, 201, 0.03)}
.bg-semi-transparent-2 {background: rgba($primary, 0.12)}

.bg-semi-success {background: rgba($success, 0.05)}
.bg-semi-success-2 {background: rgba($success, 0.2)}

.bg-semi-danger {background: rgba($danger, 0.05)}
.bg-semi-danger-2 {background: rgba($danger, 0.2)}
.bg-semi-danger-3 {background: rgba($dark-red, 0.12)}

.bg-semi-warning {background: rgba($warning, 0.05)}
.bg-semi-warning-2 {background: rgba($warning, 0.2)}

.bg-semi-primary {background: rgba($primary, 0.05)}
.bg-semi-primary-2 {background: rgba($primary, 0.2)}
.bg-semi-primary-3 {background: rgba($primary, 0.12)}

.bg-semi-blue {background: rgba(#299FBA, 0.12); color: #299FBA;}
.bg-semi-yellow {background: rgba($yellow, 0.12); color: $yellow;}

.rounded-1px {border-radius: 1px}
.rounded-2px {border-radius: 2px}
.rounded-3px {border-radius: 3px}
.rounded-4px {border-radius: 4px}
.rounded-5px {border-radius: 5px}
.rounded-6px {border-radius: 6px}
.rounded-7px {border-radius: 7px}
.rounded-8px {border-radius: 8px}
.rounded-9px {border-radius: 9px}
.rounded-10px {border-radius: 10px}

.progress-bar-bg {
  opacity: 0.2;

  &.bg-primary {
    background: rgb(81, 99, 118) !important;
    opacity: 0.7;
  }
}

#tradingview_widget_wrapper {
  height: 500px !important;

  @media screen and (max-device-width: 900px) and (orientation: landscape) {
    height: 300px !important;
  }
}

.rotate-45 {transform: rotate(45deg)}
.rotate-90 {transform: rotate(90deg)}
.rotate-135 {transform: rotate(135deg)}
.rotate-180 {transform: rotate(180deg)}
.rotate-270 {transform: rotate(270deg)}
.token-logo {
  img {
    width: 100%;
  }
}

.border-solid {border-style: solid}

.twitter-iframe {
  iframe {
    @include media-breakpoint-down(md) {
      max-height: 400px;
    }
  }
}

.gecko-top-placeholder,
.gecko-placeholder {
  width: 100%;
  position: absolute;
  left: 0;
  background: $secondary;
}

.gecko-placeholder {
  height: 65px;
  border-bottom-left-radius: $card-border-radius;
  border-bottom-right-radius: $card-border-radius;
  bottom: 0;
}

.gecko-top-placeholder {
  top: $spacer;
  height: 40px;
  border-top-left-radius: $card-border-radius;
  border-top-right-radius: $card-border-radius;
}

.trade-item {
  transition: all .5s ease;

  &.new-trade {
    opacity: 0;
    transform: translateX(-50%);
    animation: newTrade .5s ease forwards;
    border: 2px solid $primary;
  }
}

@keyframes newTrade {
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.no-scroll-padding {
  .os-viewport {
    padding: 0 !important;
    margin-bottom: 0 !important;
  }
}

.scroll-padding-sm {
    .os-viewport {
      padding: 0 0 0 12px !important;
      margin-bottom: -12px !important;
    }
}

.form-group {
  position: relative;
}

.show-password {
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
}

.transition {
  transition: all .2s ease;
}

.img-cover {
  object-fit: cover;
}

.blur-10 {filter: blur(10px)}
.blur-5 {filter: blur(5px)}
.blur-3 {filter: blur(3px)}

.absolute-item {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

w3m-modal {
  position: fixed;
  z-index: 1100;
}

.ai-score {
  width: 60%;
  height: 60%;
  left: 20%;
  top: 20%;
  animation: aiScore 5s linear infinite;
}

@keyframes aiScore {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.score-bar {
  width: 100%;
  height: 8px;
  border-radius: 100px;
}

.subscription-payment-status {
  position: relative;
  width: 22px;
  height: 22px;
  border-radius: 100%;

  &:before,
  &:after {
    content: '';
    position: absolute;
    border-radius: 100%;
  }
  &:before {
    @extend .bg-semi-transparent-2;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    animation: PaymentStatusPulse2 3s linear infinite;
  }
  &:after {
    background: $primary;
    width: 8px;
    height: 8px;
    left: 50%;
    top: 50%;
    margin-left: -4px;
    margin-top: -4px;
    animation: PaymentStatusPulse1 3s linear infinite;
  }
  &.success {
    &:before {
      @extend .bg-semi-success;
    }
    &:after {
      background: $success;
    }
  }
  &.danger {
    &:before {
      @extend .bg-semi-danger;
    }
    &:after {
      background: $danger;
    }
  }
}

@keyframes PaymentStatusPulse1 {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes PaymentStatusPulse2 {
  0% {
    transform: scale(0);
  }
  25% {
    transform: scale(1);
  }
  50% {
    transform: scale(0);
  }
  75% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.gradient-transition {
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to right, $secondary, rgba($secondary, 0));
    z-index: 10;
  }
  &:after {
    left: auto;
    right: 0;
    background: linear-gradient(to left, $secondary, rgba($secondary, 0));
  }

  &.transition-dark {
    &:before,
    &:after {
      background: linear-gradient(to bottom, $dark, rgba($dark, 0));
    }
  }
}

.sticky {
  position: sticky;
  background: rgba($dark, 0.5);
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.no-filter {
  .sticky {
    backdrop-filter: none;
  }
}

.styled-toggle {
  .react-toggle-track {
    width: 40px;
    background: $gray-700;
  }
  .react-toggle-thumb {
    border-color: $gray-700;
    box-shadow: none !important;
  }
  &.react-toggle--checked {
    .react-toggle-track {
      background: $primary;
    }
    .react-toggle-thumb {
      left: 17px;
      border-color: $primary;
    }
  }
  &:hover:not(.react-toggle--disabled) .react-toggle-track {
    background: $gray-800;
  }
}

.text-over-line {
  position: relative;
  width: 100%;
  color: $text-muted;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  span:nth-child(1),
  span:nth-child(3) {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      top: 50%;
      margin-top: -1px;
      background: $text-muted;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
  }

  span:nth-child(2) {
    display: block;
    background: $dark;
    padding: 0 1rem;
  }
}

.btn {
  &.btn-hover-svg-fill-black {
    &:hover,
    &:focus {
      svg {
        transition: fill .15s ease-in-out;
        fill: $black;
        * {
          transition: fill .15s ease-in-out;
          fill: $black;
        }
      }
    }
  }
  &.btn-primary {
    &:hover,
    &:focus {
      color: $white;
      background: rgba($primary, .2);
      border-color: rgba($primary, .2);
    }
  }
}

.dropdown {
  > button {
    &:after {
      display: none;
    }
    &:hover {
      background-color: $secondary;
      border-color: $secondary;
    }
    &:active,
    &.show {
      background-color: $gray-800 !important;
      border-color: $gray-800 !important;
    }
  }
  .dropdown-menu {
    z-index: 1050;
    //background-color: $gray-800;
    //border-color: $gray-800;
  }
  .dropdown-item {
    background-color: $gray-800;
    color: $white;
    font-size: 14px;
  }
  #gas-dropdown {
    .dropdown-item {
      cursor: text;
    }
  }
}

#swap-widget {
  & > div {
    box-shadow: none;
    width: 100%;
  }
}

#trade-history-table {
  thead {
    th {
      color: $text-muted;
      font-weight: normal;
    }
  }
  margin-left: $card-spacer-x * -1;
  margin-right: $card-spacer-x * -1;

  th:first-child,
  td:first-child {
    padding-left: $card-spacer-x !important;
  }
  th:last-child,
  td:last-child {
    padding-right: $card-spacer-x !important;
  }
}

.card-button {
  background: rgba($primary, .12);
  color: $primary;
  width: 100%;
  height: 40px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
}

.carousel-inner {
  height: 100%;
}

.carousel-indicators [data-bs-target] {
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background: $primary;
  border: none;
  margin: 0 4px;
  opacity: .2;
  transition: all .2s ease;
  &:hover,
  &.active {
    opacity: 1;
    background: $primary;
  }
  &.active {
    width: 36px;
  }
}

.row.row-x-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

wcm-modal {
  position: relative;
  z-index: 2000;
}

.z-index-2000 {
  z-index: 2000 !important;
}

.full-page {
  width: 100%;
  @include media-breakpoint-up(md) {
    height: 100%;
  }
  @include media-breakpoint-down(md) {
    padding: 7rem 0;
  }
}
