.progress-line {
  background: linear-gradient(90deg, #FF6258 0%, #FF9156 29.69%, #FFE16D 54.17%, #8EEFB2 80.73%, #70E59B 100%);
  width: 100%;
  height: 8px;
  border-radius: 100px;
  position: relative;
  &.multi-color {
    background: linear-gradient(90deg, #5B8DF0 0%, #5BB1F0 16.67%, #64E1CC 33.33%, #71E69C 49.71%, #FFDE6D 68.27%, #FF8057 83.63%, #FF6359 100%);
  }
  &.with-bars {
    background: none;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    .progress-line--bar {
      height: 100%;
      &:first-child {
        margin-right: 1px;
        background: linear-gradient(270deg, #2FA45A 0.23%, #72E89D 100%);
      }
      &.middle-bar {
        background: $gray-700;
      }
      &:last-child {
        margin-left: 1px;
        background: linear-gradient(270deg, #F49870 0%, #E24C37 100%);
      }
      &.empty-bar,
      &.full-bar {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .progress-line-value {
    position: absolute;
    top: -1px;
    left: 0;
    width: 10px;
    height: 10px;
    background: $primary;
    border-radius: 100px;
    outline: 5px solid $white;
    transition: all 1s ease-in-out;
  }
}
