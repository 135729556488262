.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -10px;
  padding: 0;
  list-style: none;
  border-radius: 0.25rem;
  overflow: hidden;

  li {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left-width: 0;
    margin-left: 10px;
    margin-right: 10px;
    color: $gray-600;

    &.previous,
    &.next {
      display: none;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      background: $secondary;
      border-radius: 10px;
      font-size: 14px;

      &:hover {
        color: $gray-600;
      }
    }
  }

  .active {
    color: $white;

    a {
      pointer-events: none;
      cursor: default;
    }
  }

  .break {
    width: 100%;

    a {
      width: 100%;
    }
  }

  .disabled {
    opacity: 0.5;

    a {
      pointer-events: none;
      cursor: default;
    }
  }
}
