.marquee-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  //padding-left: 100%;
  height: 32px;
  display: flex;
  align-items: center;

  .marquee {
    position: absolute;
    white-space: nowrap;
    display: flex;
  }
}
