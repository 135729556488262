.home_header {
  position: relative;
  background: url('../../assets/images/header.png') center bottom no-repeat;
  background-size: cover;

  &:before {
    content: '';
    position: absolute;
    width: 358px;
    max-width: 95%;
    height: 184px;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    background: rgba(65, 255, 255, 0.05);
    filter: blur(37px);
    z-index: -1;
  }
}
