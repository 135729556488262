.div-table {
  display: table;

  .table-row {
    display: table-row;
    &:hover {
      background-color: rgba($gray-700, 0.1);
    }
    @include media-breakpoint-down(md) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .table-col {
    display: table-cell;
    padding: 1rem 0.5rem;
    border-bottom: solid 1px $secondary;
    vertical-align: middle;
  }
  .table-header {
    display: table-header-group;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .table-footer {
    display: table-footer-group;
  }
  .table-body {
    display: table-row-group;
    @include media-breakpoint-down(md) {
      display: block;
    }
  }
}

#tokens-table {
  .col-volume,
  .col-mcap {
    max-width: 150px;
    word-break: break-all;
  }
  @include media-breakpoint-down(md) {
    .table-row {
      width: 100%;
      display: flex;
      padding: $grid-gutter-width / 2;
      border-bottom: 1px solid $secondary;

      .table-col {
        padding: 0;
        display: block;
        border-bottom: none;
      }

      .col-logo {
        order: 1;
        flex-basis: 50%;
        padding-bottom: 1rem;
      }

      .col-price {
        order: 2;
        //flex-basis: calc(50% - 40px - 2rem);
        flex-basis: 50%;
        padding: 0 !important;
        text-align: right;
      }

      .col-favorite {
        order: 3;
        flex-basis: 40px;
        min-width: 40px;
        max-width: 40px;
        > div {
          min-width: 40px;
        }
      }

      .col-liquidity {
        order: 4;
        flex-basis: calc(100% / 3);
        padding-left: 0 !important;
      }

      .col-volume {
        order: 5;
        flex-basis: calc(100% / 3);
      }

      .col-mcap {
        order: 6;
        flex-basis: calc(100% / 3);
        padding-right: 0 !important;
      }

      .col-holders {
        order: 7;
        flex-basis: calc(100% / 3);
        padding-right: 0 !important;
      }
      .col-favorite,
      .col-liquidity,
      .col-volume,
      .col-mcap,
      .col-holders {
        padding: 0 $grid-gutter-width / 4;
        > div {
          width: 100%;
          text-align: center;
          border-radius: $border-radius;
          padding: $grid-gutter-width / 4;
          background: $secondary;
        }
      }

      .col-date,
      .col-exchanges,
      .col-price-change {
        display: none;
      }
    }
  }
}
