$font-family-sans-serif: Olney, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-size-base: 1rem;
$font-size-sm:                $font-size-base * .875;
$font-size-lg:                $font-size-base * 1.25;

$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #516376;
$gray-800: #1C2936;
$gray-900: #0E1D2C;
$black:    #030611;

$blue:          #299FBA;
$indigo:        #4D43B3;
$purple:        #6f42c1;
$pink:          #d63384;
$red:           #FD4438;
$red-light:     #EF6B7E;
$dark-red:      #CD0000;
$orange:        #AA5A10;
$yellow:        #D3AA3F;
$green:         #3FCC73;
$green-light:   #A7FFAA;
$dark-green:    #16871A;
$brown:         #AA5A10;
$teal:          #20c997;
$cyan:          #1C5D6C;
$dark-blue:     #071524;
$dark:          #050A1D;

$primary:       $blue;
$success:       $green;
$info:          $cyan;
$warning:       $brown;
$danger:        $red;
$light:         $gray-100;
$secondary:     $dark-blue;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "gray":       $gray-700,
  "dark":       $dark,
  "pink":       $pink,
  "cyan":       $cyan,
  "orange":     $orange,
  "blue":       $blue,
  "purple":     $purple,
  "dark-red":   $dark-red,
  "dark-green": $dark-green,
  "dark-blue":  $dark-blue,
);

$body-bg: $dark;
$body-color: $white;

$text-muted:    $gray-700;

$spacer: 1rem;

//$border-radius: 1.5rem;
//$border-radius-sm: $border-radius * 0.75;
//$border-radius-lg: $border-radius * 1.25;
$border-radius:               .5rem;
$border-radius-sm:            .25rem;
$border-radius-lg:            1rem;
$border-radius-xl:            2rem;
$border-radius-2xl:           3rem;
$border-radius-pill:          50rem;

//$border-width: 2px;
//
//$body-bg: $dark;
//$body-color: $white;
$modal-content-bg: $body-bg;
$modal-content-color: $body-color;
$modal-content-border-width: 0;
$modal-content-border-color: $primary;
$modal-header-border-color: $primary;
$modal-backdrop-bg:                 $secondary;
$modal-backdrop-opacity:            .9;
$modal-content-border-radius:       $border-radius;
//$modal-header-padding-y: $spacer * 1.5;
//$modal-header-padding-x: $spacer * 2.5;
//$modal-inner-padding: $spacer * 2.5;
//
//$font-weight-lighter:         lighter;
//$font-weight-light:           300;
//$font-weight-normal:          400;
//$font-weight-semibold:        600;
//$font-weight-bold:            700;
//$font-weight-bolder:          800;
//
//$font-weight-base:            $font-weight-semibold;
//
$enable-shadows: false;

//$btn-font-family: Qore;
$btn-border-width: 1px;
$btn-padding-y:         .75rem;
$btn-padding-x:         1.75rem;
$btn-padding-y-lg:      1rem;
$btn-padding-x-lg:      2.25rem;
$btn-close-color: $white;
//$btn-font-weight: $font-weight-base;
//$btn-border-radius:           $border-radius / 2;
//$btn-border-radius-sm:        $border-radius-sm / 2;
//$btn-border-radius-lg:        $border-radius-lg / 2;
//$input-border-radius:         $border-radius / 2;
//$input-border-radius-sm:      $border-radius-sm / 2;
//$input-border-radius-lg:      $border-radius-lg / 2;
//$input-disabled-bg:           $gray-800;
$input-btn-padding-y:         .75rem;
$input-btn-padding-x:         .75rem;
$input-btn-focus-box-shadow:  none;
$input-bg:                    $secondary;
$input-border-color:          $secondary;
$input-group-addon-bg:        $input-bg;
$input-group-addon-color:     $text-muted;

//$global-padding: 1.25rem;

$card-bg: $secondary;
$card-spacer-y: $spacer * 1.25;
$card-spacer-x: $spacer * 1.25;
$card-border-width: 0;
$card-border-radius: 10px;
//$card-border-color: $gray-600;
$card-color: $white;
//$card-box-shadow: 0 0 20px -10px rgba($black, .2);
//
//$component-active-color: $dark;
//
//$headings-font-weight: 700;
//
//$accordion-bg: $gray-600;
//$accordion-button-active-bg: $primary;
//$accordion-button-active-color: $dark;
//
//$table-border-color: $dark;
//$table-border-width: 2px;



// Progress bars

// scss-docs-start progress-variables
$progress-height:                   .5rem;
$progress-bg:                       $dark;
$progress-border-radius:            $border-radius;
$progress-box-shadow:               none;
$progress-bar-color:                $primary;
$progress-bar-bg:                   $dark;
// scss-docs-end progress-variables


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1320px,
);
// scss-docs-end container-max-widths

// Dropdowns
//
// Dropdown menu container and contents.

// scss-docs-start dropdown-variables
$dropdown-font-size:                $font-size-sm;
$dropdown-color:                    $white;
$dropdown-bg:                       $gray-800;

$dropdown-link-color:               $primary;
$dropdown-link-hover-bg:            darken($primary, 10%);

$dropdown-link-disabled-color:      $gray-700;

$dropdown-header-color:             $gray-700;
// scss-docs-end dropdown-variables

