// Override default variables before the import
@import "custom-variables";
@import "fonts";

// Import Bootstrap and its default variables
@import 'bootstrap';

/* ############### UTILITIES/HELPER CLASSES ############### */
@import "util/background";
@import "util/border";
@import "util/height";
@import "util/margin";
@import "util/misc";
@import "util/padding";
@import "util/position";
@import "util/typography";
@import "util/width";

// Pages
@import "components/main-layout";
@import "pages/home";

// Components
@import "components/overlay";
@import "components/pagination";
@import "components/swipeable";
@import "components/dex-chart";
@import "components/circle-chart";
@import "components/progress-line";
@import "components/tokens-table";
@import "components/search";
@import "components/wallet";
@import "components/marquee";
@import "react-swipeable-list/dist/styles.css";
@import "react-toggle/style.css";

// Global style
@import 'global';
