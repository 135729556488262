.wallet-dropdown {
  transition: all .3s;
  position: relative;

  #wallet-content {
    position: absolute;
    top: 100%;
    margin-top: 6px;
    right: 0;
    background: $gray-800;
    border-radius: $border-radius;
    padding: 24px 8px 10px;
    display: none;
    width: 380px;
    min-width: 380px;

    @include media-breakpoint-down(sm) {
      width: 100%;
      min-width: 90vw;
      max-width: 310px;
    }

    .wallet-menu-list {
      margin-left: -8px;
      margin-right: -8px;
      padding-left: 8px;
      padding-right: 8px;
    }

    .wallet-menu-button {
      border-radius: $border-radius;
      height: 48px;

      &:hover,
      &:focus {
        background: rgba($text-muted, .3);
      }
    }

    .btn-transparent {
      &.tx-danger {
        &:hover,
        &:focus {
          background-color: rgba($dark-red, .12) !important;
          color: $danger;
          svg {
            fill: $danger;
            * {
              fill: $danger;
            }
          }
        }
      }
      &.tx-primary {svg {
        stroke: $primary;
        * {
          stroke: $primary;
        }
      }
        &:hover,
        &:focus {
          background-color: rgba($primary, .12) !important;
          color: $primary;
          svg {
            stroke: $primary;
            * {
              stroke: $primary;
            }
          }
        }
      }
    }
  }

  &.expanded {
    z-index: 1015;
    > .cur-pointer {
      > div {
        background: $gray-800;
      }
    }

    #wallet-content {
      display: block;
    }
  }
}
