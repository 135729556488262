.circle-chart {
  width: 140px;
  max-width: 100%;
  height: auto;
  position: relative;

  .circle-chart-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:not(.circle-chart--fixed) {
    .circle-chart-icon {
      font-size: 1.5rem;
    }
  }

  &.circle-chart--sm {
    svg.circle-chart-svg {
      max-width: 30px;
    }
    .circle-chart-icon {
      font-size: 12px;
    }
  }

  &.circle-chart--lg {
    width: 100px;

    svg.circle-chart-svg {
      width: 100%;
      max-width: 100px;
    }
    .circle-chart-icon {
      font-size: 20px;
    }
  }

  &.circle-chart--xl {
    width: 150px;

    svg.circle-chart-svg {
      width: 100%;
      max-width: 150px;
    }
    .circle-chart-icon {
      font-size: 28px;
    }
  }

  svg.circle-chart-svg {
    transform: rotate(-90deg);

    .circle-chart-bg,
    .circle-chart-value {
      stroke: $primary;
    }
    .circle-chart-value {
      transition: all 1s ease;
    }
    .circle-chart-bg {
      opacity: 0.4;
    }
  }
  &.tx-dark-red svg.circle-chart-svg {
    .circle-chart-bg,
    .circle-chart-value {
      stroke: $dark-red;
    }
  }
  &.tx-warning svg.circle-chart-svg {
    .circle-chart-bg,
    .circle-chart-value {
      stroke: $warning;
    }
  }
  &.tx-dark-green svg.circle-chart-svg {
    .circle-chart-bg,
    .circle-chart-value {
      stroke: $dark-green;
    }
  }
}
