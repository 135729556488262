.rstra-container {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: inline-flex;
  box-sizing: border-box;
  margin-bottom: 1rem;
}

.rstra-container > div {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: inline-flex;
  box-sizing: border-box;
}

.rstra-actions-container {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.rstra-content-container {
  width: 100%;
  display: block;
  position: absolute;
  height: 90px;
  top: 0;
  left: 0;
  transition: all 0.25s ease;
  background-color: $secondary;
  box-sizing: border-box;
  border-radius: 10px;
}

.rstra-action-button {
  min-width: 40px;
  appearance: none;
  padding: 0;
  margin: 0;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: $secondary;
  border-radius: 10px;
}

.rstra-dots-button {
  min-width: 50px;
  appearance: none;
  border: none;
  box-sizing: border-box;
  color: inherit;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}
