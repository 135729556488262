.search-bar {
  transition: all .3s;
  width: 300px;
  min-width: 300px;
  position: relative;

  #search-content {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: $dark-blue;
    border-top: 1px solid $gray-900;
    border-radius: 0 0 $border-radius $border-radius;
    padding: 30px;
    height: 500px;
    display: none;
    overflow: auto;

    .table-header {
      .table-col {
        background: $gray-900;
      }
    }
    .table-col {
      padding: 0.75rem 0.5rem;
    }
    .table-body {
      .table-col {
        border-bottom: 1px solid $gray-900;
      }
    }
    .table-row {
      transition: all .3s;
      transform: translateX(100%);
    }
  }

  &.expanded {
    width: 100%;
    z-index: 1015;

    .input-group-text {
      border-bottom-left-radius: 0;
    }
    .form-control {
      border-bottom-right-radius: 0;
    }

    #search-content {
      display: block;
      .table-row {
        transform: translateX(0);
      }
    }
  }
}
