$sidebar-width: 60px;

#main-layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  height: 100vh;
  width: 100vw;
  background-color: $body-bg;
  color: $body-color;
}

#sidebar {
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: $sidebar-width;
  min-width: $sidebar-width;
  max-width: $sidebar-width;
  background-color: $secondary;
  padding: 1.5rem 0.25rem;

  @include media-breakpoint-up(md) {
    display: flex;
  }
}

#container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  padding: 0;
  height: 100%;
  overflow: auto;

  @include media-breakpoint-up(md) {
    width: calc(100vw - #{$sidebar-width});
    min-width: calc(100vw - #{$sidebar-width});
    max-width: calc(100vw - #{$sidebar-width});
  }
}

#header {
  top: 0;
  min-height: 80px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  border-bottom: 1px solid $secondary;
  z-index: 1010;

  @include media-breakpoint-down(md) {
    padding: 0 $grid-gutter-width / 2;
  }
}

#content {
  padding: 30px;

  @include media-breakpoint-down(md) {
    padding: $grid-gutter-width / 2;
  }
}

#footer {
  background-color: $secondary;
  padding: 60px 30px 25px;
  margin-top: 30px;
  margin-bottom: -30px;

  @include media-breakpoint-down(md) {
    padding: $grid-gutter-width / 2;
    margin-bottom: $grid-gutter-width / -2;
  }
}

.no-gutter {
  margin-left: -30px;
  margin-right: -30px;
  padding-left: 30px;
  padding-right: 30px;

  @include media-breakpoint-down(md) {
    margin-left: $grid-gutter-width / -2;
    margin-right: $grid-gutter-width / -2;
  }

  .p-gutter {
    padding-left: 30px;
    padding-right: 30px;
  }

  .table-col:first-child {
    padding-left: 30px !important;
  }
  .table-col:last-child {
    padding-right: 30px !important;
  }

  @include media-breakpoint-down(md) {
    margin-left: -$grid-gutter-width / 2;
    margin-right: -$grid-gutter-width / 2;
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
    .p-gutter {
      padding-left: $grid-gutter-width / 2;
      padding-right: $grid-gutter-width / 2;
    }
  }
}

.mt-gutter {
  margin-top: -30px;
  padding-top: 30px;

  @include media-breakpoint-down(md) {
    margin-top: $grid-gutter-width / -2;
    padding-top: $grid-gutter-width / 2;
  }
}

#mobile-navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  height: 52px;
  background-color: $secondary;
  padding: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1013;
  border-top: 1px solid rgba($text-muted, .1);
  //transform: translateX(-100%);
  //transition: transform 0.3s ease-in-out;

  //&.active {
  //  transform: translateX(0);
  //}

  @include media-breakpoint-up(md) {
    display: none;
  }
}
