.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($dark, .7);
  z-index: 1011;
  overflow: auto;

  @supports (backdrop-filter: blur(10px)) {
    background: rgba($dark, 0.7);
    backdrop-filter: blur(3px);

    .form-control:not(.bg-semi-transparent),
    .list-item.card {
      background: rgba($secondary, 0.8);
    }
  }
  @supports (-webkit-backdrop-filter: blur(10px)) {
    background: rgba($dark, 0.7);
    -webkit-backdrop-filter: blur(3px);

    .form-control:not(.bg-semi-transparent),
    .list-item.card {
      background: rgba($secondary, 0.8);
    }
  }
}

.modal-backdrop {
  @supports (backdrop-filter: blur(10px)) {
    backdrop-filter: blur(3px);

    .form-control,
    .list-item.card {
      background: rgba($secondary, 0.8);
    }
  }
  @supports (-webkit-backdrop-filter: blur(10px)) {
    -webkit-backdrop-filter: blur(3px);

    .form-control,
    .list-item.card {
      background: rgba($secondary, 0.8);
    }
  }
}
